import React from 'react'

import { PrivacyWrapper, LogoSection, ContentWrapper } from './styles'

import Container from '../UI/Container'
import Logo from '../Logo'
const PrivacyTermsContent = ({ data, locale }) => {
  const { logo_subtitle, content } = data

  return (
    <PrivacyWrapper>
      <Container>
        <LogoSection>
          <Logo mode="dark" locale={locale} />
          <h4>{logo_subtitle}</h4>
        </LogoSection>
        <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </PrivacyWrapper>
  )
}

export default PrivacyTermsContent
