import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import PrivacyTermsContent from 'components/Privacy-terms-screen'

const MainContent = styled.main`
  background-color: #ffffff;
`

const CookiesPolicy = ({ data, pageContext, location }) => {
  const {
    cookies: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { content, logo_subtitle }
    }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <PrivacyTermsContent data={{ content, logo_subtitle }} locale={pageContext.locale} />
    </Layout>
  )
}

export default CookiesPolicy

export const query = graphql`
  query($id: Int, $locale: String!) {
    cookies: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        logo_subtitle
        content
      }
    }
  }
`
